/* You can add global styles to this file, and also import other style files */
@import "./assets/themes/nebular.scss";
@import "./assets/themes/datatable.scss";
@import "./assets/themes/block-ui.scss";

@import "@nebular/theme/styles/globals";

@include nb-install() {
  @include nb-theme-global();
}

@media only screen and (max-width: 1024px) {
  body {
    overflow-y: auto !important;
  }
}

// FOR GOOGLE MAPS AUTOCOMPLETE
.pac-container {
  z-index: 10000 !important;
}

html.cdk-global-scrollblock {
  overflow-y: auto;
  position: static;
}

// FOR BILLING-REPORT
ngx-tooltip-content.ngx-charts-tooltip-content {
  height: 2.75rem;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nb-theme-dark .chart svg text {
  fill: #fff;
}

.nb-theme-cosmic .chart svg text {
  fill: #fff;
}

.chart .advanced-pie-legend .legend-items-container .legend-items {
  overflow: unset !important;
}

button.custom-icon {
  padding: 0.5625rem !important;
  svg {
    transition: .2s;
    width: 20px;
    height: 20px;
  }
}

.default, .material {
  button.custom-icon {
    svg {
      fill: #36f;
    }
  }
}

.dark {
  button.custom-icon {
    svg {
      fill: #8FC9FE;
    }
  }
}

.cosmic, .cosmic__header {
  button.custom-icon {
    svg {
      fill: #B3A6FF;
    }
  }
}

.custom-svg {
  fill: var(--card-text-color);
  width: 20px;
  height: 20px;
}

.nb-theme-dark .vis-time-axis .vis-text,
.nb-theme-cosmic .vis-time-axis .vis-text {
  color: #ffffff;
}

button:disabled.custom-icon {
  svg {
    fill: rgba(143,155,179,0.48);
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container__table__buttons {
  display: flex;
}

.fixed-placeholder {
  min-height: 21px;
}

.nb-theme-default nb-layout.no-min-height .layout {
  min-height: unset;
}

.nb-theme-default nb-select.appearance-outline.status-basic.disabled-selector button:disabled,
.nb-theme-default input.disabled-selector {
  color: #646f86 !important;
}

.nb-theme-default input.disabled-selector::placeholder {
  color: #646f86 !important;
}

.nb-theme-default input[nbInput]::placeholder,
.nb-theme-dark input[nbInput]::placeholder,
.nb-theme-cosmic input[nbInput]::placeholder {
  font-family: Inter;
}

.ngx-datatable.dark .datatable-header .datatable-header-cell,
.ngx-datatable.dark .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.9rem 1.2rem;
}

datatable-scroller {
  width: 100% !important;
}

.nb-theme-dark nb-menu .menu-item > .menu-items > .menu-item a.active,
.nb-theme-dark nb-menu .menu-item a.active,
.nb-theme-dark nb-menu .menu-item a.active .menu-icon,
.nb-theme-dark [nbButton].appearance-ghost.status-primary:not(:disabled), .nb-theme-dark .appearance-ghost.status-primary[nbButtonToggle]:not(:disabled),
.ngx-datatable.dark__header .datatable-header {
  color: #8FC9FE;
}

.nb-theme-dark nb-menu .menu-item > .menu-items > .menu-item a:hover,
.nb-theme-dark nb-menu .menu-item > .menu-items > .menu-item a.active:hover,
.nb-theme-dark nb-menu .menu-item a:hover,
.nb-theme-dark nb-menu .menu-item a:hover .menu-icon,
.nb-theme-dark [nbButton].appearance-ghost.status-primary:not(:disabled):hover, .nb-theme-cosmic .appearance-ghost.status-primary[nbButtonToggle]:not(:disabled):hover {
  color: #b9deff;
}

.nb-theme-cosmic nb-menu .menu-item > .menu-items > .menu-item a.active,
.nb-theme-cosmic nb-menu .menu-item a.active,
.nb-theme-cosmic nb-menu .menu-item a.active .menu-icon,
.nb-theme-cosmic [nbButton].appearance-ghost.status-primary, .nb-theme-cosmic .appearance-ghost.status-primary[nbButtonToggle],
.ngx-datatable.cosmic__header .datatable-header {
  color: #B3A6FF;
}

.nb-theme-cosmic [nbButton].appearance-ghost.status-primary:hover, .nb-theme-cosmic .appearance-ghost.status-primary[nbButtonToggle]:hover,
.nb-theme-cosmic nb-menu .menu-item > .menu-items > .menu-item a:hover,
.nb-theme-cosmic nb-menu .menu-item > .menu-items > .menu-item a.active:hover,
.nb-theme-cosmic nb-menu .menu-item a:hover,
.nb-theme-cosmic nb-menu .menu-item a:hover .menu-icon {
  color: #d2caff;
}

.nb-theme-cosmic nb-menu .menu-item,
.nb-theme-cosmic nb-menu .menu-item .menu-item:first-child {
  border-bottom: 1px solid #343879;
}

.nb-theme-cosmic [nbButton].appearance-ghost:focus:not(:hover):not(:active), .nb-theme-cosmic .appearance-ghost[nbButtonToggle]:focus:not(:hover):not(:active) {
  box-shadow: none;
}

.text-wrap .datatable-body-cell-label {
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
}

ngx-datatable .datatable-body-cell-label {
  button {
    white-space: normal;
  }
}

.nb-theme-cosmic nb-stepper .header .step.selected .label-index {
  border-color: #b4b4db;
  background-color: #b4b4db57;
  color: #fff;
}

.nb-theme-cosmic nb-stepper .header .step.selected {
  color: #5F00BE;
}

.nb-theme-cosmic nb-stepper .header .label-index {
  border-color: #b4b4db;
}

.nb-theme-cosmic nb-stepper .header .connector {
  background-color: #390072;
}

.nb-theme-dark nb-stepper .header .step.selected .label-index {
  border-color: #8f9bb3;
  background-color: #8f9bb366;
  color: #ffffff;
}

.default-price {
  background-color: #7ed8901f !important;
}

nb-accordion-item-body.custom-padding {
  .item-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.full-width {
  width: 100%;
}

body {
  .jwplayer.jw-state-error {
    width: 100% !important;

    .jw-preview {
      background-color: transparent !important;
    }

    .jw-error-msg {
      display: none !important;
    }
  }

  .export-video {
    .jwplayer.jw-state-error {
      .jw-error-msg {
        top: 50%;
      }
    }
  }
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
  padding-left: 1rem;
}

@media screen and (max-width: 768px) {
  .ngx-datatable .datatable-footer .datatable-footer-inner {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
    height: auto !important;
  }

  .ngx-datatable .datatable-footer .datatable-pager {
    text-align: center !important;
  }

  .player-container {
    .sldp_player_wrp.sldp_player_wrp_video {
      height: unset !important;
    }
  }
}

.ngx-datatable.material .empty-row,
.ngx-datatable.dark .empty-row {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
}

.fill-favorite {
  fill: #FFC226;
}

// nb-spinner {
//   z-index: 1040 !important;
// }

// .cdk-overlay-container {
//   z-index: 1041 !important;
// }

// .cdk-global-overlay-wrapper {
//   z-index: 1042 !important;
// }

// .cdk-overlay-connected-position-bounding-box {
//   z-index: 1043 !important;
// }

// nb-list-item.cdk-drag-preview {
//   z-index: 1044 !important;
// }

nb-spinner {
  z-index: 1000 !important;
}

.nb-theme-default .cdk-overlay-container,
.nb-theme-cosmic .cdk-overlay-container,
.nb-theme-dark .cdk-overlay-container {
  z-index: 1040 !important;
}

.netDisk .chart-legend .legend-wrap {
  width: 100%;
  .legend-label-text {
    width: 100%;
  }
}

.default .chart-legend .legend-label {
  color: #6e727a;
}

nb-select { button:disabled { span { pointer-events: none; } } }

.container.add-camera {
  nb-card-body {
    padding: 1rem .5rem 1rem 1rem;
    nb-header {
      .header {
        margin-bottom: 0;
      }
    }

    nb-stepper {
      .header {
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      .step-content {
        padding: .5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .gm-style .gm-style-iw-c {
    max-width: 80vw !important;
  }
}

@media screen and (orientation:landscape) and (hover: none) {
  .player.sldp-player {
    height: 100%;
    .player-container {
      height: 100%;
      .sldp_player_wrp.sldp_player_wrp_video {
        height: 100% !important;
      }
    }
  }
}

.badge-primary {
  background-color: #007bff;
}

.badge-warning {
  background-color: #ff9c07;
}

.openstreetmaps {
  .leaflet-popup-content {
    margin: 0;
    padding: 1rem;

    @media only screen and (max-width: 768px) {
      width: 220px !important;
    }
  }
  .info--window {
    height: auto;

    display: grid;
    grid-template-columns: 152px 1fr;
    grid-template-areas:
      "thumbnail title"
      "thumbnail address"
      "thumbnail watch";
    gap: 0.8rem;

    color: nb-theme(color-basic-700);

    .thumbnail {
      grid-area: thumbnail;

      .thumbnail__privacy-mode {
        background-color: #000;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 140px;
        aspect-ratio: 16/9;

        svg {
          fill: #ffffff;
          max-width: 40px;
          max-height: 40px;
        }
      }
    }

    .title {
      grid-area: title;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      .badge {
        display: inline-block;
        padding: 0.25rem 0.4rem;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        color: nb-theme(color-basic-100);
      }

      .badge-success {
        background-color: nb-theme(color-success-500);
      }

      .badge-danger {
        background-color: nb-theme(color-danger-500);
      }
      
      .warning-non-standard-camera {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        padding: 0.2rem;

        svg {
          fill: #FFAA00;
          width: 32px;
          height: 32px;
        }
      }
    }

    .address {
      grid-area: address;

      font-size: 0.75rem;
    }

    .watch {
      grid-area: watch;
      width: max-content;
      gap: 0.5rem;
      display: flex;
    }

    @media screen and (max-width: 768px) {
      width: unset;
      grid-template-columns: 1fr;
      grid-template-areas: unset;

      .thumbnail {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .thumbnail, .title, .address, .watch {
        grid-area: unset;
      }

      .watch {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
      }
    }
  }
  .info--window.info--window-flex {
    display: flex;
    flex-direction: column;
    max-width: 320px;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }
}

.ngx-charts-outer {
  height: auto !important;
}

.powerbi-container {
  height: 1000px;
  iframe {
    border: none;
  }
}

* {
  font-family: 'Inter' !important;
}

.chart-legend .legend-wrap {
  width: 100%;
}

.add-announcement, .import-cameras {
  .container__body {
    .step-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
}

.nb-form-control-container {
  display: flex;
  flex-direction: column;
}

body.nb-theme-default nb-action nb-icon,
nb-action nb-icon {
  color: #fff;
}

body.nb-theme-default nb-actions.size-small nb-action, body.nb-theme-dark nb-action, body.nb-theme-cosmic nb-action {
  border-left: 2px solid var(--header-divider-color);
}

image-cropper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jwplayer.jw-stretch-exactfit .jw-media video {
  transform: none !important;
}

.jwplayer {
  max-height: 700px;
}

.full-screen {
  .jwplayer {
    max-height: 100%;
  }

  .col .player {
    max-height: unset;
  }
}

.no-margin {
  margin: 0;
}

.toast-container .toast-group .toast-group__top p.toast-title,
.toast-container .toast-group .toast-group__bottom p.toast-content {
  font-family: 'Inter' !important;
}

.toast-container .toast-group .toast-group__top p.toast-title {
  font-size: 15px !important;
}

.toast-container .toast-group .toast-group__bottom p.toast-content {
  font-size: 14px !important;
}

.nb-theme-default .reset-walkthrough nb-stepper .step-content,
.reset-walkthrough nb-stepper .step-content {
  padding: 0;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-background-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.container___timeline {
  .vis-item,
  .vis-item.vis-selected:not(.custom-item) {
    background-color: #F2F6FF;
    border-color: #97b0f8;
  }

  .vis-item.custom-item {
    height: 16px;
    min-width: 8px;
    border-radius: 2px !important;
    transition: all .2s;
    z-index: 3 !important;
    cursor: pointer;

    .vis-item-content {
      padding: 0px 5px;
      height: 100%;
      &>div {
        font-size: 8px;
        display: flex;
        align-items: center;
        height: 100%;
        gap: 4px;
        font-weight: 600;
        &>img {
          width: 10px;
          height: 10px;
        }
      }
    }

    .vis-item-overflow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .vis-item.person {
    background-color: var(--color-danger-600);
    border-color: var(--color-danger-600);
    color: #fff;
    &.vis-selected {
      background-color: var(--color-danger-800);
    }

    &:hover {
      background-color: var(--color-danger-400);
    }
  }

  .vis-item.animal {
    background-color: #00B887;
    border-color: #00B887;
    color: #fff;
    &.vis-selected {
      background-color: var(--color-success-800);
    }

    &:hover {
      background-color: var(--color-success-400);
    }
  }

  .vis-item.fire {
    background-color: #b83a00;
    border-color: #b83a00;
    color: #fff;
    &.vis-selected {
      background-color: #7c2801;
    }

    &:hover {
      background-color: #d4561c;
    }
  }

  .vis-item.gun {
    background-color: #006FD6;
    border-color: #006FD6;
    color: #ffffff;
    &.vis-selected {
      background-color: #0059ac;
    }

    &:hover {
      background-color: #1a89f1;
    }
  }

  .vis-item.general {
    height: 44px;

    &.items-2 {
      height: 64px;
    }

    &.items-3 {
      height: 84px;
    }

    &.items-4 {
      height: 104px;
    }
  }

  .vis-item.group-2 {
    top: 10px !important;
  }

  .vis-item.group-3 {
    top: 30px !important;
  }

  .vis-item.group-4 {
    top: 50px !important;
  }

  .vis-item.group-5 {
    top: 70px !important;
  }

  .vis-item.hidden-item {
    visibility: hidden !important;
  }

  .vis-group {
    display: flex;
    align-items: center;
  }

  .vis-item-content {
    div>img {
      // width: 4px;
      // height: 4px;
    }
  }
}