@use "../../../node_modules/@swimlane/ngx-datatable/index.css";
@use "../../../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@use "../../../node_modules/@swimlane/ngx-datatable/themes/dark.scss";
@use "../../../node_modules/@swimlane/ngx-datatable/assets/icons.css";

@use "./nebular.scss";

.ngx-datatable.material {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.12);

  .datatable-header-cell {
    background-color: #f7f9fc !important;
  }
}

.datatable-header-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.ngx-datatable.dark .datatable-body-cell-label {
  color: #fff !important;
}

.datatable-body-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .datatable-body-cell-label {
    font-size: 16px;
    color: #4b4b4b;
  }

  .badge {
    display: inline-block;
    padding: 0.25rem 0.4rem;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: nb-theme(color-basic-100);
  }

  .badge-success {
    background-color: nb-theme(color-success-500);
  }

  .badge-danger {
    background-color: nb-theme(color-danger-500);
  }

  .badge-secondary {
    background-color: nb-theme(color-basic-700);
  }

  .badge-blue {
    background-color: #3366FF;
  }

  .badge-green {
    background-color: #00D68F;
  }
}

.datatable-row-detail {
  .badge {
    display: inline-block;
    padding: 0.25rem 0.4rem;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: nb-theme(color-basic-100);
  }

  .badge-blue {
    background-color: #3366FF;
  }

  .badge-green {
    background-color: #00D68F;
  }

  .badge-success {
    background-color: nb-theme(color-success-500);
  }

  .badge-danger {
    background-color: nb-theme(color-danger-500);
  }
}

.cell-ellipsis .datatable-body-cell-label {
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
}

.ngx-datatable .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}

.ngx-datatable .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  transform: translate(0, 0) scale(1, 1);
}

.ngx-datatable .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}


.ngx-datatable.cosmic__header .datatable-body .progress-linear .container {
  background-color: #4d3882;
}

.ngx-datatable.dark__header .datatable-body .progress-linear .container {
  background-color: #435592;
}

.ngx-datatable.dark__header .datatable-body .progress-linear .container .bar {
  background-color: #499cf1;
}

.ngx-datatable.cosmic__header .datatable-body .progress-linear .container .bar {
  background-color: #aa49f1
}