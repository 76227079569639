@import "@nebular/theme/styles/theming";
@import "@nebular/theme/styles/themes/default";
@import "@nebular/theme/styles/themes/cosmic";
@import "@nebular/theme/styles/themes/dark";

// default theme https://akveo.github.io/nebular/docs/design-system/default-theme

// $nb-enable-css-custom-properties: true;

$nb-enabled-themes: (default, cosmic, dark);

$nb-themes: nb-register-theme(
  (
    header-background: #384777,
    user-initials-text-color: color-basic-100,
    app-border: color-basic-300,
    color-success-500: #03BB7E,
    linear-gradient: linear-gradient(146.92deg, #3B83C7 4.96%, #243378 89.7%),
    modal-header-gradient: linear-gradient(103.36deg, #384777 0.25%, #000A28 94.55%),
    color-accent: #3366ff,
    color-accent-dark: #153596,
    font-family-primary: 'Inter',
    font-family-secondary: 'Inter',
    text-heading-1-font-family: 'Inter',
    text-heading-2-font-family: 'Inter',
    text-heading-3-font-family: 'Inter',
    text-heading-4-font-family: 'Inter',
    text-heading-5-font-family: 'Inter',
    text-heading-6-font-family: 'Inter',
    text-subtitle-font-family: 'Inter',
    text-subtitle-2-font-family: 'Inter',
    text-paragraph-font-family: 'Inter',
    text-label-font-family: 'Inter',
    text-caption-font-family: 'Inter',
    text-caption-2-font-family: 'Inter',
    text-button-font-family: 'Inter',
    header-height: 61px,
    header-divider-color: #4678a7,
    header-gradient: linear-gradient(180deg, #0a244c 0%, #1336a0 100%),
    scrollbar-width:	.35rem,
  ),
  default,
  default
);

$nb-themes: nb-register-theme(
  (
    header-background: #12153D,
    app-border: color-basic-1000,
    color-primary-default: #5F00BE,
    color-primary-500: #5F00BE,
    color-primary-hover: #4B0196,
    color-primary-default-border: #5F00BE,
    color-primary-focus: #390072,
    color-primary-active: #390072,
    color-primary-active-border: #390072,
    color-primary-focus-border: #390072,
    background-basic-color-1: #12153D,
    background-basic-color-3: #0C0E28,
    border-basic-color-3: #343879,
    shadow:	0 0.5rem 1rem 0 #0b0d27,
    scrollbar-color: #12153D,
    border-basic-color-4: #12153D,
    divider-color: #343879,
    color-success-500: #03BB7E,
    linear-gradient: linear-gradient(146.92deg, #52049F 4.96%, #462CAE 89.7%),
    modal-header-gradient: linear-gradient(146.92deg, #52049F 4.96%, #462CAE 89.7%),
    color-accent: #B3A6FF,
    color-accent-dark: #6256a8,
    font-family-primary: 'Inter',
    font-family-secondary: 'Inter',
    text-heading-1-font-family: 'Inter',
    text-heading-2-font-family: 'Inter',
    text-heading-3-font-family: 'Inter',
    text-heading-4-font-family: 'Inter',
    text-heading-5-font-family: 'Inter',
    text-heading-6-font-family: 'Inter',
    text-subtitle-font-family: 'Inter',
    text-subtitle-2-font-family: 'Inter',
    text-paragraph-font-family: 'Inter',
    text-label-font-family: 'Inter',
    text-caption-font-family: 'Inter',
    text-caption-2-font-family: 'Inter',
    text-button-font-family: 'Inter',
    button-outline-primary-text-color: #b3a6ff,
    color-primary-transparent-default-border: #b3a6ff,
    button-outline-primary-background-color: rgb(12 14 40),
    header-height: 61px,
    header-divider-color: #7d71be,
    header-gradient: linear-gradient(180deg, #52049F 0%, #462CAE 100%),
    menu-submenu-item-active-hover-text-color: #B3A6FF,
    scrollbar-width:	.35rem,
  ),
  cosmic,
  cosmic
);

$nb-themes: nb-register-theme(
  (
    header-background: #1a1f33,
    app-border: color-basic-1000,
    color-primary-default: #0387E7,
    color-primary-500: #0387E7,
    color-primary-hover: #0076CC,
    color-primary-default-border: #0387E7,
    color-primary-focus: #0162a9,
    color-primary-active: #0162a9,
    color-primary-active-border: #0162a9,
    color-primary-focus-border: #0162a9,
    shadow: 0 0.5rem 1rem 0 #151a30,
    color-success-500: #03BB7E,
    linear-gradient: linear-gradient(146.92deg, #3B83C7 4.96%, #243378 89.7%),
    modal-header-gradient: linear-gradient(146.92deg, #3B83C7 4.96%, #243378 89.7%),
    color-accent: #8FC9FE,
    color-accent-dark: #3b6892,
    font-family-primary: 'Inter',
    font-family-secondary: 'Inter',
    text-heading-1-font-family: 'Inter',
    text-heading-2-font-family: 'Inter',
    text-heading-3-font-family: 'Inter',
    text-heading-4-font-family: 'Inter',
    text-heading-5-font-family: 'Inter',
    text-heading-6-font-family: 'Inter',
    text-subtitle-font-family: 'Inter',
    text-subtitle-2-font-family: 'Inter',
    text-paragraph-font-family: 'Inter',
    text-label-font-family: 'Inter',
    text-caption-font-family: 'Inter',
    text-caption-2-font-family: 'Inter',
    text-button-font-family: 'Inter',
    header-height: 61px,
    header-divider-color: #4f68b1,
    header-gradient: linear-gradient(180deg, #132f6f 0%, #2154A1 100%),
    menu-submenu-item-active-hover-text-color: #8FC9FE,
    scrollbar-width:	.35rem,
  ),
  dark,
  dark
);

// change header color in default theme

.nb-theme-default nb-user .user-name {
  color: #fff !important;
}

.nb-theme-default nb-user .initials {
  color: #fff !important;
}

li.route-tab {
  a {
    outline: none;
  }
}

body.nb-theme-default nb-layout-header nav {
  height: var(--header-height);
}

body.nb-theme-default nb-layout-header.fixed~.layout-container {
  padding-top: var(--header-height);
}

.compacted nb-menu .menu-item .menu-icon,
.nb-theme-default .compacted nb-menu .menu-item .menu-icon {
  margin-right: 0;
}

.nb-theme-cosmic .compacted nb-menu .menu-item, .nb-theme-cosmic .compacted nb-menu .menu-item .menu-item:first-child,
.nb-theme-dark .compacted nb-menu .menu-item, .nb-theme-dark .compacted nb-menu .menu-item .menu-item:first-child,
body.nb-theme-default .compacted nb-menu .menu-item {
  border-bottom: 0;
}

body.nb-theme-default nb-layout .layout .layout-container nb-sidebar.fixed, body.nb-theme-default nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
  top: var(--header-height);
}

body.nb-theme-default nb-layout-header.fixed~.layout-container nb-sidebar .main-container {
  height: calc(100vh - var(--header-height));
}

body.nb-theme-default nb-layout-header nav,
body.nb-theme-cosmic nb-layout-header nav,
body.nb-theme-dark nb-layout-header nav {
  padding: 0 .9rem;
}

.nb-theme-default nb-sidebar,
nb-sidebar {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

body.nb-theme-default nb-user.size-medium .user-picture,
.nb-theme-cosmic nb-action nb-user .user-picture,
.nb-theme-dark nb-action nb-user .user-picture {
  height: 2.2rem;
  width: 2.2rem;
  border-color: #fff;
}

nb-action svg.theme-selector {
  fill: #fff;
  width: 23px;
  height: 24px;
}

nb-card.modal {
  border: 0 !important;
  padding: 0 !important;
  border-radius: 8px !important;
  margin-bottom: 0;
  nb-card-header {
    background: var(--modal-header-gradient);
    color: nb-theme(color-primary-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    position: relative;
    min-height: 3rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .title {
      font-size: 0.875rem;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
    }

    .close-btn {
      position: absolute !important;
      right: .5rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

input[type="text"] {
  text-overflow: ellipsis;
}

.toggle-label span.text {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}